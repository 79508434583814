$header-height-mobile: 7.8rem;
$header-height-desktop: 8rem;
$scrollbar-thumb-bg: #325680;
$model-page-header-border-color: rgba($white, 0.1);
$model-nav-padding-vertical-desktop: 1.9rem;
$model-nav-padding-vertical-fullhd: 1.9rem;
$model-nav-padding-vertical: 0;
$sub-nav-cta-font-weight-key: medium;
$map-phone-title-family-key: secondary;
$map-phone-title-color: $brownish-grey;

/****** BREADCRUMB INDENTS *******/
$breadcrumb-indent-mobile: 0;
$breadcrumb-indent-mobile-small: 20px;
$breadcrumb-indent-tablet: 20px;
$breadcrumb-indent-desktop: 15px;
$breadcrumb-indent-widescreen: 25px;
$breadcrumb-indent-fullhd: 27px;
$breadcrumb-indent-fullwidth: 27px;

/* grid/_grid */
$default-theme-text: $text;
$light-theme-text: $text;
$dark-theme-text: $white;
$default-theme-bg: $white;
$light-theme-bg: $grey-light;
$dark-theme-bg: $primary;

/* core/_buttons */
$button-border-radius: $corner-lg;
$button-arrow-color: $primary;
$button-border-width: 1px;
$button-filter-color: $primary;
$button-filter-color-active: $primary;

// primary
$button-primary-bg: $primary;
$button-primary-color: $white;
$button-primary-border-color: $primary;
$button-primary-hover-bg: $blue;
$button-primary-active-bg: $blue;
$button-primary-text-hover-color: $white;
$button-primary-border-hover-color: $blue;
$button-primary-disabled-bg: $grey-lighter;
$button-primary-disabled-border-color: $grey-lighter;
$button-primary-disabled-text-color: $grey;

/* elements/_form */
$input-background-color: transparent;
$input-background-color-light: transparent;
$input-background-color-dark: transparent;
$input-color: $primary;
$input-color-light: $primary;
$input-color-dark: $white;
$input-border-color: $black;
$input-border-color-light: $grey;
$input-border-color-dark: $white;
$input-hover-border-color: $blue;
$input-focus-border-color: $blue;
$input-hover-border-color-light: $blue;
$input-focus-border-color-light: $blue;
$input-hover-border-color-dark: $blue;
$input-focus-border-color-dark: $blue;
$input-radius: $corner-lg;
$checkmark-border-radius: $corner-sm;
$checkmark-checked-color: $white;
$checkmark-checked-background-color: $primary;
$checkmark-top-align: 2px;

/* hero-spot */
$hero-title-font-weight: bold;

/* consent component */
$consent-text-color: $black;
$consent-text-color-hover: $blue;

/****** BULMA OVERRIDES *******/
$control-border-width: 1px;
