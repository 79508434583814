.field {
    .input {
        border-width: 1px;
    }

    .radio,
    .checkbox {
        input:checked ~ .checkmark {
            @include when-inside('.grid__theme--default') {
                border-color: $black;
            }

            @include when-inside('.grid__theme--light') {
                border-color: $black;
            }

            @include when-inside('.grid__theme--dark') {
                border-color: $white;
            }
        }
    }
}

%field-text {
    @include when-inside('.grid__theme--default') {
        color: $input-color;

        &::placeholder {
            color: $black;
        }
    }

    @include when-inside('.grid__theme--light') {
        color: $input-color-light;

        &::placeholder {
            color: $black;
        }
    }

    @include when-inside('.grid__theme--dark') {
        color: $input-color-dark;

        &::placeholder {
            color: $black;
        }
    }
}
