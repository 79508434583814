/****** COLORS *******/
$white: white;
$black: #0d0e0e;
$blue: #67b0c4;
$grey: #d1d3d5;

$grey-darker: #5d5d5d;
$grey-dark: #b0b2b4;
$grey-light: #e2e4e6;
$grey-lighter: #f1f2f3;

$blue-dark: #4f8a9b;

/****** Text colors ******/
$text: $black;
$text-light: $grey-darker;

/****** General colors *******/
$foreground: $white;
$background: $black;
$primary: $black;
$border: $black;
