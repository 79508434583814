@import './_variables/color-variables';

/****** COMMON DEFAULT VARIABLES *******/
@import '../../../../libs/common-styles/src/lib/styles/styles-variables';

/****** VARIABLES *******/
@import '_variables/color-variables';
@import '_variables/corner-variables';
@import './_variables/variables';
@import './_variables/typography';

/****** COMMON LIB *******/
@import '../../../../libs/common-styles/src/lib/styles/styles';

/****** CORE *******/
@import 'core/buttons';

/****** COMPONENTS *******/
@import './components/navbar';

/****** ELEMENTS *******/
@import './elements/consent';
@import './elements/form';

/****** FEATURES *******/
@import './features/footer';
@import './features/header';
@import './features/meta-menu';
@import './features/main-menu';
@import './features/mobile-menu';

/****** SPOTS *******/
@import './spots/extended-hero-spot';
@import './spots/headline-spot';
