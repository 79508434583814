.headline-spot {
    .spot-content {
        &__header--title {
            @include until($tablet) {
                font-size: $font-size-large;
            }
        }

        &__header--subtitle {
            @include when-inside('.grid__theme--default', '.grid__theme--light', '.grid__theme--dark') {
                padding-top: 0;
                color: $grey-darker;

                @include until($tablet) {
                    padding-top: 1rem;
                }
            }

            @include when-inside('.grid__theme--default', '.grid__theme--light') {
                color: $grey-darker;
            }

            @include when-inside('.grid__theme--dark') {
                color: $white;
            }
        }
    }
}
